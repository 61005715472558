import {useTranslation} from 'react-i18next'
import React, {useMemo, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {Box, Grid, Container, Tooltip, Typography, Link} from '@mui/material'

import BWGSection from 'views/Home/BWGSection'
import TrackCustomPreference from 'consentCheck'
import BusinessModel from 'views/Home/BusinessModel/BusinessModel'
import ConnectWallet from 'views/Home/ConnectWallet'
import SliderSection from 'views/Home/SliderSection'
import Advantages from 'views/Home/Advantages'
import AppsSection from 'views/Home/AppsSection'
import ValueImg from 'images/affiliate/earning.svg'
import FreedomImg from 'images/affiliate/freedom.svg'
import ConvenienceImg from 'images/affiliate/convenience.svg'

import {loginUSUrl, loginEUUrl, registerEUUrl} from 'config'

import YoutubeLink from './YoutubeLink'
import {Wrapper, RegisterButton, AffiliateButton} from './Affiliate.styles'

const generatLink = (userType, device, server, token) => {
  if (!token) {
    return registerEUUrl
  }
  if (device === 'Web') {
    const webUrl = server === 'US' ? loginUSUrl : loginEUUrl
    const affiliateQuery = userType === 'affiliate' ? '&user=affiliate' : ''
    return `${webUrl}/en/invite?token=${token}${affiliateQuery}`
  } else if (device !== 'Web') {
    const affiliateQuery = userType === 'affiliate' ? '/affiliate' : ''
    return `brettonwoods://register/${token}/${server}${affiliateQuery}`
  }
}

const AffiliateSections = ({isChecked, setIsChecked, device, token, server}) => {
  const {t} = useTranslation()

  const openRegisterLink = userType => {
    const link = generatLink(userType, device, server, token)
    if (device === 'Web') {
      window.open(link, '_blank')
    } else {
      const webLink = generatLink(userType, 'Web', server, token)
      window.location.replace(link)
      setTimeout(() => window.open(webLink, '_blank'), 1000)
    }
  }

  const openTermsAndConditions = () => {
    setIsChecked(true)
    window.open(`${window.origin}/documents/affiliate_agreement.pdf`, '_blank')
  }

  return (
    <Box
      sx={{
        marginTop: '30px',
        display: 'flex',
        marginBottom: '40px',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <AffiliateButton onClick={openTermsAndConditions}>
        {t('buttons.termsAndConditions')}
      </AffiliateButton>
      {/* <Link
        component='a'
        href={`${window.origin}/documents/affiliate_agreement.pdf`}
        target='_blank'
      >
        Affiliate Terms & Conditions
      </Link> */}
      {!isChecked ? (
        <Tooltip
          enterTouchDelay={0}
          title={<span style={{fontSize: 16}}>{t('connectWallet.tooltip')}</span>}
          arrow
          placement='top-start'
        >
          <span>
            <RegisterButton disableRipple disabled={!isChecked}>
              {t('buttons.register.affiliate')}
            </RegisterButton>
          </span>
        </Tooltip>
      ) : (
        <RegisterButton onClick={() => openRegisterLink('affiliate')}>
          {t('buttons.register.affiliate')}
        </RegisterButton>
      )}
      <RegisterButton onClick={() => openRegisterLink('customer')}>
        {t('buttons.register.customer')}
      </RegisterButton>
    </Box>
  )
}
// const getDeviceName = () => {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera

//   let device = 'Web'

//   const isAndroid = /Android/i.test(userAgent)
//   const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream

//   if (isAndroid) {
//     device = 'Android'
//   } else if (isiOS) {
//     device = 'iOS'
//   }

//   return device
// }

const getDeviceName = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  let device = 'Web'

  const isAndroid = /Android/i.test(userAgent)
  const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
  const isStandalone =
    window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches

  if (isAndroid) {
    device = 'Android'
  } else if (isiOS) {
    if (isStandalone) {
      device = 'iOS'
    } else {
      device = 'Web'
    }
  }

  return device
}

const AffiliateView = () => {
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const [isChecked, setIsChecked] = useState(false)

  const token = useMemo(() => searchParams.get('token') || '', [searchParams])
  const server = useMemo(() => searchParams.get('server') || 'EU', [searchParams])

  const device = getDeviceName()

  const affiliateLink = useMemo(() => {
    return generatLink('affiliate', 'Web', server, token)
  }, [token, server])

  const customerLink = useMemo(() => {
    return generatLink('customer', 'Web', server, token)
  }, [token, server])

  return (
    <Container>
      <Wrapper>
        {/*   Video Section for   */}
        <Typography variant='h2' sx={{marginTop: {xs: 5, md: 0}}}>
          {t('affiliateView.videoSection.video1.title')}
        </Typography>
        <YoutubeLink videoUrl={t('affiliateView.videoSection.video1.videoUrl')} />

        <Typography variant='h2' sx={{marginTop: {xs: 5, md: 5}}}>
          {t('affiliateView.videoSection.video2.title')}
        </Typography>
        <YoutubeLink videoUrl={t('affiliateView.videoSection.video2.videoUrl')} />

        <Typography variant='subtitle1' sx={{marginTop: {xs: 2, md: 2}}}>
          {t('affiliateView.videoSection.info')}{' '}
          <Link
            target='_blank'
            href={t('affiliateView.videoSection.link')}
            color='success.main'
            onClick={() =>
              TrackCustomPreference({
                action: 'Link Clicked',
                properties: {
                  url: t('affiliateView.videoSection.link'),
                  text: t('affiliateView.videoSection.linkText'),
                  meaning: 'Open brettonwoods digital website media',
                },
              })
            }
          >
            {t('affiliateView.videoSection.linkText')}
          </Link>
        </Typography>

        {/* Affiliate terms and conditions */}
        <AffiliateSections
          device={device}
          token={token}
          server={server}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />

        {/* Benefit of joining */}
        <Typography variant='h2' sx={{marginTop: '70px'}} align='center'>
          {t('affiliateView.benefits.title')}
        </Typography>

        <Box sx={{width: '100%', marginTop: '15px'}}>
          <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{marginBottom: '15px', marginTop: '15px'}}
                display='flex'
                gap='10px'
                alignItems='center'
              >
                <Box component='img' src={FreedomImg} width='25px' height='25px' />
                <div>{t('affiliateView.benefits.subTitle1')}</div>
              </Box>
              <Typography variant='body2' component='li'>
                {t('affiliateView.benefits.description1')}
              </Typography>
              <Typography variant='body2' component='li'>
                {t('affiliateView.benefits.description2')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{marginBottom: '15px', marginTop: '15px'}}
                display='flex'
                gap='10px'
                alignItems='center'
              >
                <Box component='img' src={ValueImg} width='25px' height='25px' />
                <div>{t('affiliateView.benefits.subTitle2')}</div>
              </Box>
              <Typography variant='body2' component='li'>
                {t('affiliateView.benefits.description3')}
              </Typography>
              <Typography variant='body2' component='li'>
                {t('affiliateView.benefits.description4')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{marginBottom: '15px', marginTop: '15px'}}
                display='flex'
                gap='10px'
                alignItems='center'
              >
                <Box component='img' src={ConvenienceImg} width='30px' height='30px' />
                <div>{t('affiliateView.benefits.subTitle3')}</div>
              </Box>
              <Typography variant='body2' component='li'>
                {t('affiliateView.benefits.description5')}
              </Typography>
              <Typography variant='body2' component='li'>
                {t('affiliateView.benefits.description6')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <BWGSection />
        <BusinessModel />
        <ConnectWallet
          affiliateLink={affiliateLink}
          isChecked={isChecked}
          customerLink={customerLink}
        />
        <SliderSection />

        <AffiliateSections
          device={device}
          token={token}
          server={server}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />
        <AppsSection />

        <Advantages />
      </Wrapper>
    </Container>
  )
}

export default AffiliateView
